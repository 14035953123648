import React, { useState } from 'react'
import tw from 'twin.macro'
import { useForm } from 'react-hook-form'

// Components
import Layout from '../components/Layout'
import {
  ButtonStyle,
  CopyStyle,
  HeaderStyle,
  SubheadSMStyle,
} from '../components/UI'
import CallToAction from '../components/CallToAction'
import { GatsbyImage } from 'gatsby-plugin-image'
import Seo from '../components/Seo'

const DeleteAccount = ({ location, data }) => {
  const [formSubmitted, setFormSubmitted] = useState(false)

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm()

  const onSubmit = (data) => {
    const params = {
      name: data.name,
      email: data.email,
    }

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    }

    fetch('https://v2.staffy.com/api/account-removal', options)
      .then((response) => response.json())
      .then((response) => {
        if (response.data) setFormSubmitted(true)
      }).catch((err) => {
        console.log(err)
      })
  }


  return (
    <Layout location={location}>
      {/* Hero */}
      <section tw="mt-16">
        <div tw="flex flex-col lg:(grid grid-cols-[1fr repeat(12, calc(1024px/12)) 1fr] relative h-[369px])">
          <div tw="order-2 mx-7 my-12 lg:(order-1 flex flex-col justify-center mx-0 mb-0 col-start-4 col-span-8 my-0)">
            <h1 css={[HeaderStyle, tw`mb-6 font-extrabold`]}>We're so sorry to see you go.</h1>
            <p css={[CopyStyle, tw`max-h-full mb-12 mt-8`]}>
              At Staffy Health, we know how important it is that you have control over your personal
              information. If you wish to permanently delete your account, you can request to do so here.
              Please keep in mind that:
            </p>
            <ul tw="list-disc list-inside mb-4">
              <li tw="mb-4">Once your account is deleted you will not be able to access past shifts information you have worked on or posted.</li>
              <li tw="mb-4">You will not be able to create or accept shifts.</li>
              <li tw="mb-4">If you want to work or create shifts again you will need to create a new account and re-submit all your credentials.</li>
              <li tw="mb-4">Your billing and other personal information will not be accessible again.</li>
            </ul>
          </div>
        </div>
      </section>

      {/* Form */}
      <section tw="grid grid-cols-4 gap-6 mx-7 py-14 lg:(max-w-screen-lg grid-cols-12 mx-auto)">
        {!formSubmitted ? (
          <form
            onSubmit={handleSubmit(onSubmit)}
            css={[tw`col-span-4 lg:(col-start-3 col-span-8)`]}
          >
            <p tw="mb-12 mt-10" >To delete your account please complete this form:</p>
            {/* Name */}
            <div tw="mb-10">
              <label css={[SubheadSMStyle, tw`block mb-3.5`]} htmlFor="Name">
                Name
              </label>
              <div className="input-group">
                <input
                  css={[CopyStyle, tw`border border-black w-full px-7 py-4`]}
                  type="text"
                  id="Name"
                  name="name"
                  placeholder="Name"
                  {...register('name', { required: true })}
                />
                {errors.name && (
                  <span css={[CopyStyle, tw`text-red-500`]}>
                    This field is required
                  </span>
                )}
              </div>
            </div>
            {/* Email */}
            <div tw="mb-10">
              <label css={[SubheadSMStyle, tw`block mb-3.5`]} htmlFor="Email">
                Email
              </label>
              <div className="input-group">
                <input
                  css={[CopyStyle, tw`border border-black w-full px-7 py-4`]}
                  type="email"
                  id="Email"
                  name="email"
                  placeholder="Email"
                  {...register('email', {
                    required: true,
                    pattern:
                      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                  })}
                />
                {errors.email && (
                  <span css={[CopyStyle, tw`text-red-500`]}>
                    This field is required
                  </span>
                )}
              </div>
            </div>
            {/* Subject */}
            <div>
              <input
                type="submit"
                value="Submit"
                css={[
                  ButtonStyle,
                  tw`bg-white inline-block py-4 px-8 w-full text-center border-[#FF4D00] border-[1.5px] cursor-pointer lg:(px-16 w-auto)`,
                  tw`transition-all transition-duration[0.2s] transition-timing-function[cubic-bezier(0.645,0.045,0.355,1)] hover:(text-white bg-[#FF4D00])`,
                ]}
              />
            </div>
          </form>
        ) : (
          <div tw="col-span-full flex items-center justify-center text-green-500 text-[24px] mt-20">
            Your account deletion request has been submitted successfully!
          </div>
        )}
      </section>



    </Layout >
  )
}

export default DeleteAccount
